import React from "react";
import $ from "jquery";
import Typed from "react-typed";
import "react-typed/dist/animatedCursor.css";

export default class App extends React.Component {
  componentDidMount() {
    $(window).on("load", function () {
      $("#status").fadeOut();
      $("#preloader").delay(350).fadeOut("slow");
      $("body").delay(350).css({
        overflow: "visible",
      });
    });

    //Sticky
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        $(".sticky").addClass("nav-sticky");
      } else {
        $(".sticky").removeClass("nav-sticky");
      }
    });

    // Back to top
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $(".back-to-top").fadeIn();
      } else {
        $(".back-to-top").fadeOut();
      }
    });
    $(".back-to-top").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 3000);
      return false;
    });
  }

  render() {
    return (
      <>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
        <header id="topnav" className="defaultscroll sticky">
          <div className="container">
            <div>
              <a className="logo" href="/">
                MAM
              </a>
            </div>
          </div>
        </header>
        <section
          className="bg-half-150 d-table w-100"
          style={{
            background: "url('images/vectors/home-bg.png') center center",
            id: "home",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="title-heading mt-4">
                  <h1 className="heading mb-3">
                    Something We
                    <i className="mdi mdi-heart header-icon-color"></i>
                    <br />
                    <Typed
                      strings={[
                        "Data Transformation",
                        "Web Development",
                        "API Development",
                        "Data Presentation",
                      ]}
                      typeSpeed={50}
                      backSpeed={20}
                      placeholder="string"
                      loop
                      showCursor={true}
                    >
                      <input type="text" />
                    </Typed>
                  </h1>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img
                  src="images/vectors/undraw_data_trends_b0wg.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-4 border-bottom border-top" style={{backgroundColor:"#f8f9fa"}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <a
                  href="https://www.esa.edu.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/client/esa.png"
                    className="avatar avatar-ex-sm"
                    alt="Education Service Australia"
                  />
                </a>
              </div>
              <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                <a
                  href="https://www.telstra.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="images/client/telstra.svg"
                    className="avatar avatar-ex-sm"
                    alt="Telstra"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section overflow-hidden">
          <div
            className="container mt-100 mt-60"
            style={{ paddingBottom: "40px", marginTop: "30px" }}
          >
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src="images/vectors/undraw_design_sprint_x3eb.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-4">Things we do every day</h4>
                  <ul className="list-unstyled feature-list text-muted">
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      Agile Methodology
                    </li>
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      Sprints Planning
                    </li>
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      Coding
                    </li>
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      Code reviews
                    </li>
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      Devops
                    </li>
                    <li>
                      <i className="fea  mr-2 mdi mdi-check"></i>
                      App Maintenance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-100 mt-60">
            <div className="row align-items-center">
              <div className="col-md-5">
                <ul
                  className="nav nav-pills nav-justified flex-column rounded"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link rounded active"
                      id="pills-cloud-tab"
                      data-toggle="pill"
                      href="#pills-cloud"
                      role="tab"
                      aria-controls="pills-cloud"
                      aria-selected="false"
                    >
                      <div className="p-3 text-left">
                        <h4 className="title font-weight-bold">
                          Web Development
                        </h4>
                        <p className="text-muted tab-para mb-0">
                          We are a big fan of SPA because of its speed and
                          scalability. And we use it to develop e-Commerce and
                          business dashboards.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link border-top rounded"
                      id="pills-smart-tabs"
                      data-toggle="pill"
                      href="#pills-smart"
                      role="tab"
                      aria-controls="pills-smart"
                      aria-selected="false"
                    >
                      <div className="p-3 text-left">
                        <h4 className="title font-weight-bold">
                          Application Modernization
                        </h4>
                        <p className="text-muted tab-para mb-0">
                          We all love transformation. And we transform legacy
                          applications into cutting edge technology. Also, we
                          communicate between applications using various
                          protocols and industry best practices.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link border-top rounded"
                      id="pills-apps-tab"
                      data-toggle="pill"
                      href="#pills-apps"
                      role="tab"
                      aria-controls="pills-apps"
                      aria-selected="false"
                    >
                      <div className="p-3 text-left">
                        <h4 className="title font-weight-bold">
                          Data Transformation
                        </h4>
                        <p className="text-muted tab-para mb-0">
                          A good business decision is based on business
                          intelligence. So we work hard to get that every bit of
                          business data and transform into business intelligence
                          for you and your business partners.
                        </p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="saas-feature-shape-right position-relative">
                  <div className="tab-content ml-lg-4" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-cloud"
                      role="tabpanel"
                      aria-labelledby="pills-cloud-tab"
                    >
                      <img
                        src="images/vectors/undraw_code_review_l1q9.svg"
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-smart"
                      role="tabpanel"
                      aria-labelledby="pills-smart-tab"
                    >
                      <img
                        src="images/vectors/undraw_version_control_9bpv.svg"
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </div>

                    <div
                      className="tab-pane fade"
                      id="pills-apps"
                      role="tabpanel"
                      aria-labelledby="pills-apps-tab"
                    >
                      <img
                        src="images/vectors/undraw_statistic_chart_38b6.svg"
                        className="img-fluid mx-auto d-block"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-100 mt-60">
            <div className="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-5 mt-sm-0 pt-sm-0 justify-content-center">
              <div className="col-12 text-center">
                <div className="section-title">
                  <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                  <a
                    href="mailto:au.mamtechnology@gmail.com"
                    className="btn btn-lg btn-dark mt-2"
                  >
                    <i className="mdi mdi-email-outline"></i> Email us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer footer-bar">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="text-sm-left">
                  <p className="mb-0">
                    © 2020
                    <span className="footer-logo">MAM</span>
                    Technology
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a
          href="/"
          className="back-to-top rounded text-center"
          id="back-to-top"
        >
          <i className="mdi mdi-chevron-up"></i>
        </a>
      </>
    );
  }
}
